<!-- 安检周期配置 -->
<template>
  <div class="content">
    <div class="all" style='height:calc(100vh - 238px);overflow: auto;'>
      <p class="detil-code">管线周期配置</p>
      <div class="table">
        <!--数据表格-->
        <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%;"
          max-height="400"
          border
          :stripe="true"
          @selection-change="handleSelectionChange"
          :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
          <el-table-column prop="eqTypeName" label="管线" align="left" show-overflow-tooltip>
            <template>
              全部
            </template>
          </el-table-column>
          <el-table-column prop="checkPeriod" label="巡检周期" align="left" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.dayNumber}}天{{row.frequency}}次
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" align="left" width="250">
            <template slot-scope="{ row }">
              <el-button
                type="primary"
                size="small"
                style="margin-right:10px"
                v-if="$anthButtons.getButtonAuth('editPipeline')"
                @click="periodFun(row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <p class="detil-code" style="margin-top:30px">设备显示及周期配置
         <span style="float:right;margin-bottom:10px"> 
            <el-button type="text"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                " size="mini"
                v-if="$anthButtons.getButtonAuth('addFacility')"
                 @click="AddDataFun">添加</el-button>
          </span>
      </p>
      <div class="table">
        <!--数据表格-->
        <!-- <div class="tableTool">
          <span style="float:right;margin-bottom:10px"> 
            <el-button type="text"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                " size="mini" @click="AddDataFun">添加</el-button>
          </span>
        </div> -->
        <el-table
          ref="tableData"
          :data="facilityData"
          style="width: 100%;"
          border
          
          :stripe="true"
          @selection-change="handleSelectionChange"
          :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
          <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="left"
          width="60"
          >
          </el-table-column>
          <el-table-column prop="eqTypeName" label="巡检设备" align="left" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="checkPeriod" label="巡检周期" align="left" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div v-if="row.checkPeriod == 1">{{row.dayNumber}}天一周期</div>
              <div v-if="row.checkPeriod == 2">一月三周期</div>
              <div v-if="row.checkPeriod == 3">一月二周期</div>
              <div v-if="row.checkPeriod == 4">一月一周期</div>
              <div v-if="row.checkPeriod == 5">一季度一周期</div>
            </template>
          </el-table-column>
          <el-table-column prop="takeEffect" label="安检周期更改生效时间" align="left" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{row.takeEffect == 1 ? '立即生效/周期任务结束后生效' : '新的周期生效' }}
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" align="left" width="250">
            <template slot-scope="{ row }">
              <el-button
                type="primary"
                size="small"
                style="margin-right:10px"
                v-if="$anthButtons.getButtonAuth('editFacility')"
                @click="AddDataFun(row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                style="margin-right:10px"
                v-if="$anthButtons.getButtonAuth('deleteFacility')"
                @click="delFun(row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 管线编辑 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="450px"
      title="编辑"
      @closed="addDialogClosed"
      data-backdrop="static"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog_form">
        <el-form  :model="formline" ref="formline" :rules="rules">
          <div style="display: flex;flex-direction: row;">
          <el-form-item label="巡检周期：" prop="dayNumber" label-width="100px">
            <el-input placeholder="请输入天数" v-model="formline.dayNumber"  style="width:150px" size="small">
              <template slot="append">天</template>
              </el-input>
          </el-form-item>
          <el-form-item label="" prop="frequency" label-width="10px" v-if="dialogVisible">
            <el-input placeholder="请输入次数" v-model="formline.frequency"  style="width:150px" size="small">
              <template slot="append">次</template>
              </el-input>
          </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="diaBtn" @click="addDialogClosed">取 消</el-button>
        <el-button type="primary" @click="addPipeline">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设备弹窗 -->
    <el-dialog
      :visible.sync="dialogPeriod"
      width="450px"
      @closed="addDialogClosed"
      data-backdrop="static"
      :title="titleName"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog_form">
        <el-form label-width="80px" :model="form" ref="form" :rules="rules">
          <el-form-item label="设备类型" prop="eventTypeIdList" v-if="!form.id">
            <!-- <el-select v-model="form.eqType" placeholder="请选择" clearable style="width:300px">
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
            <el-cascader
                  
                  style="width:332px"
                  :options="userList"
                  v-model="form.eventTypeIdList"
                  :props="{  label:'name' , value:'id'  }"
                  separator='>'
                  clearable
                  collapse-tags
                  @change="deformation">
                </el-cascader>
          </el-form-item>
          <el-form-item label="巡检周期" :prop="form.checkPeriodnumber == 1 ? 'dayNumber' : ''">
            <el-radio-group v-model="form.checkPeriodnumber" size="medium" @change="onChange" 
            style="width:332px;display: flex;flex-flow:row nowrap;justify-content:space-around;">
              <el-radio-button :label="1" style="flex-grow:1">日</el-radio-button>
              <el-radio-button :label="2" style="flex-grow:1">月</el-radio-button>
              <el-radio-button :label="3" style="flex-grow:1">季度</el-radio-button>
            </el-radio-group>
            <div style="margin-top: 15px;" v-if="form.checkPeriodnumber == 1">
              <el-input placeholder="请输入1-365任意整数" v-model="form.dayNumber" style="width:332px">
                <template slot="append">天一周期</template>
              </el-input>
            </div>
            <div style="margin-top: 15px;" v-if="form.checkPeriodnumber == 2">
              <el-radio-group v-model="form.checkPeriod" style="width:332px">
                <el-radio :label="2" style="margin-bottom:10px">一月三周期</el-radio>
                <el-radio :label="3" style="margin-bottom:10px">一月二周期</el-radio>
                <el-radio :label="4" style="margin-bottom:10px">一月一周期</el-radio>
              </el-radio-group>
            </div>
            <div style="margin-top: 15px;" v-if="form.checkPeriodnumber == 3">
              <el-radio-group v-model="form.checkPeriod">
                <el-radio :label="5">一季度一周期</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="安检周期" prop="takeEffect">
            <el-radio-group v-model="form.takeEffect" style="width:332px">
              <el-radio :label="1" style="margin-bottom:10px">立即生效/周期任务结束后生效</el-radio>
              <el-radio :label="2">新的周期生效</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="diaBtn" @click="addDialogClosed">取 消</el-button>
        <el-button type="primary" @click="addUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { periodListPolling , periodInsertOrPolling ,  periodDelete ,periodDeletePolling} from "../../RequestPort/dict/cycle";
import { patrolGetDict} from "../../RequestPort/maintenance";
import {  selectDictListByParentCode } from "@/apis/commonType";
export default {
  name: 'Cycle',
  data() {
    return {
      search: {}, //搜索
      total: 10,
      tableData: [], //列表对象
      facilityData:[],// 设备
      dialogVisible: false,
      form: {
        checkPeriodnumber:1,
        checkPeriod:1,
        takeEffect:1,
        dayNumber:1,
        frequency:1,
        eventTypeIdList:[]
      }, //新增数据表单
      formline:{
        checkPeriodnumber:1,
        checkPeriod:1,
        takeEffect:1,
        dayNumber:1,
        frequency:1,
        eventTypeIdList:[]
      },
      rules: {
        dayNumber:[{ required: true, message: "请输入天数", trigger: "blur" },
        {pattern:/^\+?[1-9]\d*$/,message: "请输入大于0的正整数",}],
        frequency:[{ required: true, message: "请输入次数", trigger: "blur" },
        {pattern:/^\+?[1-9]\d*$/,message: "请输入大于0的正整数",}],
        checkPeriod:{ required: true, message: "请选择安检周期", trigger: "change" },
        takeEffect:{ required: true, message: "请选择周期", trigger: "change" },
        eventTypeIdList:{ required: true, message: "请选择设备类型", trigger: "change" },
      },
      // 设备类型
      userList:[],
      indexMethod:1,
      options:[],
      value:'',
      // 上面周期的编辑
      dialogPeriod:false,
      periodName:'天一周期',
      titleName:'添加设备周期'
    }
  },
  mounted() {
    this.getList()
    this.getUserTypes()
  },
  methods: {
    /**
     * 巡检周期-选择事件-设备
     */
    onChange(val){
      this.form.dayNumber = 1
      if(this.$refs['form']) {
        this.$refs['form'].clearValidate()
      }
      if(val == 1) {
        this.form.checkPeriod = 1
      }
      else if(val == 2) {
        this.form.checkPeriod = 2
      } else if(val == 3) {
        this.form.checkPeriod = 5
      }
      
    },
    /**
     * 事件类型 选择事件 设备
     */
    deformation(){
      this.form.eqType = this.form.eventTypeIdList[1]
      // this.getList()
    },
    //查列表
    getList() {
      // 1设备  2管线
      periodListPolling({period:2}).then(res => {
        this.tableData = res.data
      })
      periodListPolling({period:1}).then(res => {
        this.facilityData = res.data
      })
    },
    /**
     * 管线周期
     */
    periodFun(row){
      if(row.id){
        this.formline.id = row.id
        this.formline= {...this.formline,...{dayNumber:row.dayNumber,frequency:row.frequency}}
      }
      this.dialogVisible = true
    },
    //获取用户分类下拉
    getUserTypes(){
      selectDictListByParentCode({ parentCode: "sblx", level: 2 }).then(res => {
        if(res.code == 200) {
          this.userList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //打开新增/编辑弹框  设备
    AddDataFun(row) {
      this.titleName = row.id ? '编辑设备周期' : '添加设备周期'
      this.dialogPeriod = !this.dialogPeriod
      this.getUserTypes()
      // console.log(row)
      this.form =  {
        checkPeriodnumber:1,
        checkPeriod:1,
        takeEffect:1,
        dayNumber:1,
        frequency:1,
        eventTypeIdList:[]
      }
      if(row.id){
        this.form = {...this.form,...row}
        this.form.checkPeriodnumber = this.form.checkPeriod == 1 ? 1 : (this.form.checkPeriod < 5 ? 2 : 3)
        // this.form.userType = row.userTypeName
      }
      this.$nextTick(function() {
        if(this.$refs['form']) {
          this.$refs['form'].clearValidate()
        }
      })
    },
    /**
     *  管线编辑 
     */
    addPipeline(){
       this.$refs['formline'].validate(valid => {
        if (valid) {
       if(Number(this.formline.dayNumber) > 365||Number(this.formline.frequency) > 30) {
        this.$message.error('请输入1-365的正整数')
        return
      }
      periodInsertOrPolling(this.formline).then(res => {
        if (res.code == 200) {
          this.$message.success('编辑成功')
          this.dialogVisible = false
        }
        this.getList()
      })
        }
       })
    },
    //提交新增/编辑 -设备
    addUpdate(row) {
      if(Number(this.form.dayNumber) > 365) {
        this.$message.error('请输入1-365的正整数')
        return
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.id) {
            let p = {...this.form,...{period:1}}
            periodInsertOrPolling(p).then(res => {
              this.dialogPeriod = !this.dialogPeriod
              if (res.code == 200) {
                this.$message.success('编辑成功')
              }
              this.getList()
            })
          } else {
            let p = {
              ...this.form,...{period:1}
            }
            periodInsertOrPolling(p).then(res => {
              this.dialogPeriod = !this.dialogPeriod
              if (res.code == 200) {
                this.$message.success('配置完成')
              }
              this.getList()
            })
          }
        } else {
          return false
        }
      })
    },
    //关闭新增//编辑弹框
    addDialogClosed() {
      this.dialogVisible = false
      this.dialogPeriod = false
      this.$refs.form.clearValidate()
    },
    //删除
    delFun(id) {
      let msg = ''
      if (typeof id == 'object') {
        id = id.toString()
        msg = '确定删除选择的数据吗'
      } else {
        msg = '删除后不再对此设备派发周期任务，是否确认删除'
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        let params = {
          id: id
        }
        periodDeletePolling(params).then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功')
          }
          this.getList()
        })
      })
    },
    handleSelectionChange(val) {
      console.log(val)
    },
    handleSizeChange(val) {
      this.search.size = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.search.current = val
      this.getList()
    }
  }
}
</script>

<style scoped lang='less'>
.detil-code {
  padding-left: 10px;
  padding-bottom:10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.table {
  padding: 0!important;
  position: relative;
  // border: 1px solid #ecedf1;
  // border-radius: 5px;
  // margin-right: 1.5%;
}
.all {
  background-color: white;
  // padding-top: 0.5%;
}
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 0px!important;
  padding-top: 20px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 70%;
}
.lab {
  margin-left: 2%;
}
/* .dialog_form { */
  /* margin-left: 10%; */
/* } */
/deep/.el-radio-button__inner{
  width: 100%;
}
.SearchBtn2 {
  margin-left: 1%;
}
</style>
